<template>
  <Html
    :class="`theme-${config.public['theme']}`"
    :lang="config.public['country']"
  >
    <Body
      :class="{
        'no-scroll': isModalOpen,
        'header-white': isError,
        'header-absolute': isError,
      }"
    >
      <div :aria-hidden="isModalOpen">
        <component :is="header" :content="headerStory.content" />
        <slot />
        <component :is="footer" :content="footerStory.content" />
      </div>
      <div class="modals">
        <InquiryModal
          v-if="formsStory"
          ref="callbackModal"
          type="callback"
          :facility="facility"
        />
        <InquiryModal
          v-if="formsStory"
          ref="visitModal"
          type="visit"
          :facility="facility"
        />
      </div>
    </Body>
  </Html>
</template>

<script setup>
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";

const config = useRuntimeConfig();
const trackingStore = useTrackingStore();
const route = useRoute();
const props = defineProps({
  isError: {
    type: Boolean,
    required: false,
    default: () => {
      return false;
    },
  },
  header: {
    type: Object,
    required: false,
    default: GlobalHeader,
  },
  footer: {
    type: Object,
    required: false,
    default: GlobalFooter,
  },
});

const tracking = (data) => {
  trackingStore.sendTrackingData(data);
};

watch(
  () => route?.path,
  (_route) => {
    if (typeof window !== "undefined") {
      setTimeout(() => {
        if (!isModalOpen.value) {
          document.body.classList.remove("no-scroll");
        }
        trackingStore.updateRoute(_route);
        tracking({ event: "page.display" });
      }, 500);
    }
  },
  { immediate: true }
);

const countryPrefix = config.public["storyblokCountryPrefix"];
const useDidomi =
  config.public["didomiApiKey"] || config.public["didomiApiKey"] != "";

const headerStory = ref(null);
const footerStory = ref(null);
const faviconStory = ref(null);
const facility = ref(null);
const formsStory = ref(null);

await useCustomAsyncStoryblok("", {
  version: config.public["storyblokVersion"],
  by_slugs: `${countryPrefix}/header,${countryPrefix}/footer,${countryPrefix}/favicon,${countryPrefix}${
    route.path
  }${route.path.endsWith("/") ? "" : "/"},${countryPrefix}/forms`,
  resolve_links: "url",
}).then((result) => {
  headerStory.value = result.value.stories.find(
    (story) => story.slug === "header"
  );
  footerStory.value = result.value.stories.find(
    (story) => story.slug === "footer"
  );
  faviconStory.value = result.value.stories.find(
    (story) => story.slug === "favicon"
  );
  formsStory.value = result.value.stories.find(
    (story) => story.slug === "forms"
  );
  const pageStory = result.value.stories.find(
    (story) =>
      story.slug !== "header" &&
      story.slug !== "footer" &&
      story.slug !== "favicon" &&
      story.slug !== "forms"
  );
  facility.value = pageStory?.content;
});

const isModalOpen = ref(false);

const updateIsModalOpen = async () => {
  await nextTick();
  isModalOpen.value = [...document.querySelectorAll(".modal")].some(
    (modal) => modal.getAttribute("aria-hidden") !== "true"
  );
};

provide("modal", { isModalOpen, updateIsModalOpen });

const canonicalPath = function () {
  let routePath = route.path;
  if (routePath.slice(-1) == "/") routePath = routePath.slice(0, -1);
  return routePath;
};

// Global callback modal
const callbackModal = ref(null);
const openCallbackModal = () => {
  callbackModal.value?.openModal();
};

const visitModal = ref(null);
const openVisitModal = () => {
  visitModal.value?.openModal();
};

provide("callback", openCallbackModal);
provide("facility-callback", openCallbackModal);
provide("facility-visit", openVisitModal);

useAnchorFunction("#callback", openCallbackModal);
useAnchorFunction("#facility-callback", openCallbackModal);
useAnchorFunction("#facility-visit", openVisitModal);

const turnstileSitekey = config.public["turnstileSitekey"];
const isHomepage = route.path === "/";

// START Strutured Data Seo
let socialNetworksUrls = footerStory.value.content.social_networks.map(
  (item) => {
    return item.link.url;
  }
);

let structuredDataSeo = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: config.public["brandName"],
  url: config.public["baseURL"],
  image: headerStory.value.content.seo_organization_logo?.filename,
  sameAs: socialNetworksUrls,
};

if (Array.isArray(footerStory.value.content.schema_tag) && footerStory.value.content.schema_tag.length > 0){
  let schema_tags = footerStory.value.content.schema_tag.map(
    (tags) => ({
      [tags.title] : Object.assign({}, ...tags.list.map( tag => ({
        [tag.tag_name] : tag.tag_value
      })))
    })
  );
  structuredDataSeo = Object.assign(structuredDataSeo, ...schema_tags);
}

structuredDataSeo = JSON.stringify(structuredDataSeo);

useHead({
  link: [
    {
      rel: "canonical",
      href: useRequestURL().origin + canonicalPath(),
    },
    {
      rel: "apple-touch-icon",
      href: faviconStory.value.content.apple_touch_icon.filename,
    },
    {
      rel: "icon",
      href: faviconStory.value.content.icon_32.filename,
    },
    {
      rel: "icon",
      href: faviconStory.value.content.icon_16.filename,
    },
    {
      rel: "manifest",
      href: "/site.webmanifest",
    },
    {
      rel: "mask-icon",
      href: faviconStory.value.content.safari_pinned_tab.filename,
      color: `#${config.public["brandColor"]}`,
    },
    {
      rel: "preconnect",
      href: "https://fonts.googleapis.com",
    },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
      crossorigin: true,
    },
  ],
  meta: [
    {
      name: "msapplication-TileColor",
      content: "#ffffff",
    },
    {
      name: "theme-color",
      content: "#ffffff",
    },
  ],
  script: [
    useDidomi && {
      innerHTML:
        'window.gdprAppliesGlobally=false;(function(){function n(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var r=document.createElement("iframe");r.style.display="none";r.name=e;r.title=e;t.insertBefore(r,t.firstChild)}else{setTimeout(function(){n(e)},5)}}}function e(r,a,o,c,s){function e(e,t,r,n){if(typeof r!=="function"){return}if(!window[a]){window[a]=[]}var i=false;if(s){i=s(e,n,r)}if(!i){window[a].push({command:e,version:t,callback:r,parameter:n})}}e.stub=true;e.stubVersion=2;function t(n){if(!window[r]||window[r].stub!==true){return}if(!n.data){return}var i=typeof n.data==="string";var e;try{e=i?JSON.parse(n.data):n.data}catch(t){return}if(e[o]){var a=e[o];window[r](a.command,a.version,function(e,t){var r={};r[c]={returnValue:e,success:t,callId:a.callId};n.source.postMessage(i?JSON.stringify(r):r,"*")},a.parameter)}}if(typeof window[r]!=="function"){window[r]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");n("__tcfapiLocator");(function(e,t){var r=document.createElement("link");r.rel="preconnect";r.as="script";var n=document.createElement("link");n.rel="dns-prefetch";n.as="script";var i=document.createElement("link");i.rel="preload";i.as="script";var a=document.createElement("script");a.id="spcloader";a["async"]=true;a.charset="utf-8";var o="https://sdk.privacy-center.org/"+e+"/loader.js?target_type=notice&target="+t;if(window.didomiConfig&&window.didomiConfig.user){var c=window.didomiConfig.user;var s=c.country;var d=c.region;if(s){o=o+"&country="+s;if(d){o=o+"&region="+d}}}r.href="https://sdk.privacy-center.org/";n.href="https://sdk.privacy-center.org/";i.href=o;a.src=o;var f=document.getElementsByTagName("script")[0];f.parentNode.insertBefore(r,f);f.parentNode.insertBefore(n,f);f.parentNode.insertBefore(i,f);f.parentNode.insertBefore(a,f)})("' +
        config.public["didomiApiKey"] +
        '","' +
        config.public["didomiNoticeId"] +
        '")})();',
    },
    {
      innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${config.public["gtmId"]}');`,
    },
    turnstileSitekey && {
      src: "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit",
    },
    isHomepage && {
      type: "application/ld+json",
      innerHTML: structuredDataSeo,
    },
  ],
  noscript: [
    {
      innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=${config.public["gtmId"]}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      tagPosition: "bodyOpen",
    },
  ],
  style: [
    {
      media: "screen",
      innerHTML: `
.icon defs linearGradient stop:first-of-type {
  stop-color: var(--gradient-alt-start);
}
.icon defs linearGradient stop:last-of-type {
  stop-color:  var(--gradient-alt-end);
}
      `,
    },
    {
      media: "screen",
      innerHTML: `
/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggmxSvfedN62Zw.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/manrope/v15/xn7gYHE41ni1AdIRggexSvfedN4.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
    `,
    },
  ],
});

provide("isMobile", useState("isMobile").value);
provide("isDesktop", useState("isDesktop").value);
</script>
